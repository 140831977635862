var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"iiotAddEditWorkloadGoBackBtn","data-cy":"iiotAddEditWorkloadGoBackBtn","large":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.cancel()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-left ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('workloadDetail.back')))])]),_c('v-btn',{staticClass:"workload-icon ml-8",class:`${_vm.type}-btn-color`,attrs:{"id":`iiotAddEditWorkload_${_vm.type}Icon`,"disabled":true,"fab":"","dark":""}},[(_vm.type === 'docker-compose')?_c('v-img',{attrs:{"src":`/img/${_vm.type}.svg`}}):_c('v-icon',{staticClass:"workload-icon-image"},[_vm._v(" "+_vm._s(`$vuetify.icons.${_vm.type}`)+" ")])],1),_c('v-col',{staticClass:"title align-center"},[_c('h1',[_vm._v(" "+_vm._s(_vm.type === 'vm' ? _vm.$t('workloadDetail.updateVm') : (_vm.type === 'docker' ? _vm.$t('workloadDetail.updateDocker') : (_vm.type === 'docker-compose' ? _vm.$t('workloadDetail.updateCompose') : _vm.$t('workloadDetail.updateCodesys'))))+" ")])])],1),_c('div',{staticClass:"ml-16 mt-10 mr-8"},[_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"max-width-page mr-0 pr-0"},[_c('v-col',{class:{'pr-16': _vm.isMarginVisible},attrs:{"cols":"12","lg":"4"}},[_c('v-form',{ref:"workloadDetailForm",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submitForm()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{attrs:{"cols":"12","id":"iiotAddEditWorkloadName"}},[(_vm.type !== 'docker-compose')?_c('v-text-field',{attrs:{"id":"iiotAddEditWorkloadNameInput","rules":[_vm.rules.required, _vm.rules.name_contains_only_spaces,
              _vm.rules.name_contains_control_character],"placeholder":_vm.$t('workloadDetail.name'),"required":"","maxlength":_vm.maxLengthName},model:{value:(_vm.workload.name),callback:function ($$v) {_vm.$set(_vm.workload, "name", $$v)},expression:"workload.name"}}):_c('v-text-field',{attrs:{"id":"iiotAddEditWorkloadNameInput","rules":[_vm.rules.required, _vm.rules.name_contains_only_spaces,
              _vm.rules.name_contains_control_character],"placeholder":_vm.$t('workloadDetail.name'),"required":"","maxlength":_vm.maxLengthName},model:{value:(_vm.composeWorkload.name),callback:function ($$v) {_vm.$set(_vm.composeWorkload, "name", $$v)},expression:"composeWorkload.name"}})],1),_c('v-col',{attrs:{"id":"iiotAddEditWorkloadDescription","cols":"12"}},[(_vm.type !== 'docker-compose')?_c('v-textarea',{attrs:{"id":"iiotAddEditWorkloadDescriptionInput","label":_vm.$t('workloadDetail.description'),"outlined":"","rows":"7","maxlength":_vm.workloadDetailDescriptionLength},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();}},model:{value:(_vm.workload.description),callback:function ($$v) {_vm.$set(_vm.workload, "description", $$v)},expression:"workload.description"}}):_c('v-textarea',{attrs:{"id":"iiotAddEditWorkloadDescriptionInput","label":_vm.$t('workloadDetail.description'),"outlined":"","rows":"7","maxlength":_vm.workloadDetailDescriptionLength},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();}},model:{value:(_vm.composeWorkload.description),callback:function ($$v) {_vm.$set(_vm.composeWorkload, "description", $$v)},expression:"composeWorkload.description"}})],1)],1),_c('div',{staticClass:"d-flex justify-end pr-4"},[((!_vm.isEdit() && !_vm.canAccess('UI_WORKLOAD:EDIT')) || (_vm.isEdit()
              && _vm.canAccess('UI_WORKLOAD:EDIT'))
              || (!_vm.isEdit() && _vm.canAccess('UI_WORKLOAD:CREATE')))?_c('nerve-button',{staticClass:"mr-5",attrs:{"id":"iiotAddEditWorkloadDiscardChangesBtn","data-cy":"iiotAddEditWorkloadDiscardChangesBtn","text":_vm.$t('workloadDetail.discardChanges'),"disabled":!_vm.valid || !_vm.hasFormChanged,"type-of-btn":"cancel","size":"normal","autofocus":""},on:{"click-event":function($event){return _vm.cancel()}}}):_vm._e(),((!_vm.isEdit() && !_vm.canAccess('UI_WORKLOAD:EDIT')) || (_vm.isEdit()
            && _vm.canAccess('UI_WORKLOAD:EDIT'))
            || (!_vm.isEdit() && _vm.canAccess('UI_WORKLOAD:CREATE')))?_c('nerve-button',{attrs:{"id":"iiotAddEditWorkloadUpdateBtn","data-cy":"iiotAddEditWorkloadUpdateBtn","text":_vm.$t('workloadDetail.save'),"disabled":!_vm.valid || !_vm.hasFormChanged,"type-of-btn":"action","size":"normal"},on:{"click-event":function($event){return _vm.submitForm()}}}):_vm._e()],1)],1),(_vm.isMarginVisible)?_c('div',[_c('v-divider',{attrs:{"vertical":""}})],1):_vm._e(),_c('v-col',{staticClass:"mt-5",class:{'pl-6': _vm.isMarginVisible},attrs:{"cols":"12","lg":"8"}},[_c('v-row',[(_vm.versions.length > 0)?_c('v-col',{attrs:{"id":"iiotAddEditWorkloadCreateVersion","lg":"11","sm":"10"}},[_c('h4',{staticClass:"ml-3 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('workloadDetail.list.version'))+" ")])]):_vm._e(),(_vm.versions.length === 0)?_c('v-col',{attrs:{"id":"iiotAddEditWorkloadCreateVersionTitle","lg":"2","sm":"2"}},[_c('h4',{staticClass:"ml-3 mt-3"},[_vm._v(" "+_vm._s(_vm.$t('workloadDetail.list.createVersion'))+" ")])]):_vm._e(),_c('v-col',[(_vm.canAccess('UI_WORKLOAD:VERSION_CREATE'))?_c('v-btn',{attrs:{"id":"iiotAddEditWorkloadCreateVersionBtn","disabled":!_vm.valid,"color":"primary","small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.goToWorkloadVersionSpecificAddWorkload()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()],1)],1),(_vm.versions.length > 0)?_c('v-col',[_c('the-nerve-table',{attrs:{"id":'iiotAddEditWorkloadVersionTable',"columns":_vm.columns,"is-action-menu-enabled":false,"is-search-enabled":false,"have-access":true,"disable-pagination":true,"hide-footer":true,"is-row-clickable":_vm.canAccess('UI_WORKLOAD:VERSION_VIEW'),"empty-state":_vm.$t('workloadDetail.list.emptyList'),"store-module":"workloads","custom-fetch-action":"fetchVersionWithoutFilter","custom-list-getter":"getVersions"},on:{"row-clicked":params => _vm.rowClicked(params.item)}})],1):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }