<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div>
    <div class="d-flex align-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            id="iiotAddEditWorkloadGoBackBtn"
            data-cy="iiotAddEditWorkloadGoBackBtn"
            v-bind="attrs"
            v-on="on"
            large
            @click.native.stop="cancel()"
          >
            mdi-chevron-left
          </v-icon>
        </template>
        <span>{{ $t('workloadDetail.back') }}</span>
      </v-tooltip>
      <v-btn
        :id="`iiotAddEditWorkload_${type}Icon`"
        :class="`${type}-btn-color`"
        :disabled="true"
        class="workload-icon ml-8"
        fab
        dark
      >
        <v-img
          v-if="type === 'docker-compose'"
          :src="`/img/${type}.svg`"
        />
        <v-icon
          v-else
          class="workload-icon-image">
          {{`$vuetify.icons.${type}`}}
        </v-icon>
      </v-btn>
      <v-col class="title align-center">
        <h1>
          {{ type === 'vm' ? $t('workloadDetail.updateVm') :
          (type === 'docker' ? $t('workloadDetail.updateDocker') :
          (type === 'docker-compose' ? $t('workloadDetail.updateCompose') :
          $t('workloadDetail.updateCodesys'))) }}
        </h1>
      </v-col>
    </div>
    <div class="ml-16 mt-10 mr-8">
      <v-row class="max-width-page mr-0 pr-0" v-resize="onResize">
        <v-col
          cols="12"
          lg="4"
          :class="{'pr-16': isMarginVisible}"
        >
          <v-form
            ref="workloadDetailForm"
            v-model="valid"
            @submit.stop.prevent="submitForm()"
          >
            <v-col
              cols="12"
              id="iiotAddEditWorkloadName"
            >
              <v-text-field
                v-if="type !== 'docker-compose'"
                v-model="workload.name"
                id="iiotAddEditWorkloadNameInput"
                :rules="[rules.required, rules.name_contains_only_spaces,
                rules.name_contains_control_character]"
                :placeholder="$t('workloadDetail.name')"
                required
                :maxlength="maxLengthName"
              />
              <v-text-field
                v-else
                v-model="composeWorkload.name"
                id="iiotAddEditWorkloadNameInput"
                :rules="[rules.required, rules.name_contains_only_spaces,
                rules.name_contains_control_character]"
                :placeholder="$t('workloadDetail.name')"
                required
                :maxlength="maxLengthName"
              />
            </v-col>
            <v-col
              id="iiotAddEditWorkloadDescription"
              cols="12">
              <v-textarea
                v-if="type !== 'docker-compose'"
                v-model="workload.description"
                id="iiotAddEditWorkloadDescriptionInput"
                :label="$t('workloadDetail.description')"
                outlined
                @keyup.enter.native.stop=""
                rows="7"
                :maxlength="workloadDetailDescriptionLength"
              />
              <v-textarea
                v-else
                v-model="composeWorkload.description"
                id="iiotAddEditWorkloadDescriptionInput"
                :label="$t('workloadDetail.description')"
                outlined
                @keyup.enter.native.stop=""
                rows="7"
                :maxlength="workloadDetailDescriptionLength"
              />
            </v-col>
          </v-form>
          <div class="d-flex justify-end pr-4">
            <nerve-button
                v-if="(!isEdit() && !canAccess('UI_WORKLOAD:EDIT')) || (isEdit()
                && canAccess('UI_WORKLOAD:EDIT'))
                || (!isEdit() && canAccess('UI_WORKLOAD:CREATE'))"
                id="iiotAddEditWorkloadDiscardChangesBtn"
                data-cy="iiotAddEditWorkloadDiscardChangesBtn"
                :text="$t('workloadDetail.discardChanges')"
                :disabled="!valid || !hasFormChanged"
                type-of-btn="cancel"
                size="normal"
                class="mr-5"
                autofocus
                @click-event="cancel()"
            />
            <nerve-button
              v-if="(!isEdit() && !canAccess('UI_WORKLOAD:EDIT')) || (isEdit()
              && canAccess('UI_WORKLOAD:EDIT'))
              || (!isEdit() && canAccess('UI_WORKLOAD:CREATE'))"
              id="iiotAddEditWorkloadUpdateBtn"
              data-cy="iiotAddEditWorkloadUpdateBtn"
              :text="$t('workloadDetail.save')"
              :disabled="!valid || !hasFormChanged"
              type-of-btn="action"
              size="normal"
              @click-event="submitForm()"
            />
          </div>
        </v-col>
        <div v-if="isMarginVisible">
          <v-divider vertical />
        </div>
        <v-col
          cols="12"
          lg="8"
          class="mt-5"
          :class="{'pl-6': isMarginVisible}"
        >
          <v-row>
            <v-col
              id="iiotAddEditWorkloadCreateVersion"
              v-if="versions.length > 0"
              lg="11"
              sm="10"
            >
              <h4
                class="ml-3 mt-3"
              >
                {{ $t('workloadDetail.list.version') }}
              </h4>
            </v-col>
            <v-col
              id="iiotAddEditWorkloadCreateVersionTitle"
              v-if="versions.length === 0"
              lg="2"
              sm="2"
            >
              <h4
                class="ml-3 mt-3"
              >
                {{ $t('workloadDetail.list.createVersion') }}
              </h4>
            </v-col>
            <v-col>
              <v-btn
                v-if="canAccess('UI_WORKLOAD:VERSION_CREATE')"
                id="iiotAddEditWorkloadCreateVersionBtn"
                :disabled="!valid"
                color="primary"
                small
                fab
                depressed
                @click="goToWorkloadVersionSpecificAddWorkload()"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-col
            v-if="versions.length > 0"
          >
            <the-nerve-table
              :id="'iiotAddEditWorkloadVersionTable'"
              :columns="columns"
              :is-action-menu-enabled="false"
              :is-search-enabled="false"
              :have-access="true"
              :disable-pagination="true"
              :hide-footer="true"
              :is-row-clickable="canAccess('UI_WORKLOAD:VERSION_VIEW')"
              :empty-state="$t('workloadDetail.list.emptyList')"
              store-module="workloads"
              custom-fetch-action="fetchVersionWithoutFilter"
              custom-list-getter="getVersions"
              @row-clicked="params => rowClicked(params.item)"
            />
          </v-col>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { TheNerveTable, NerveButton } from 'nerve-ui-components';
import { MAX_LENGTH_NAME, WORKLOAD_DETAIL_DESCRIPTION_LENGTH, VALIDATION_REGEX } from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: {
    TheNerveTable,
    NerveButton,

  },
  data() {
    return {
      type: '',
      valid: false,
      isUpdate: false,
      maxLengthName: MAX_LENGTH_NAME,
      workloadDetailDescriptionLength: WORKLOAD_DETAIL_DESCRIPTION_LENGTH,
      hasFormChanged: false,
      workloadName: '',
      workloadDescription: '',
      isMarginVisible: false,
    };
  },
  computed: {
    versions() {
      return this.$store.getters['workloads/getVersions'];
    },
    columns() {
      if (this.canAccess('UI_WORKLOAD:VERSION_CREATE')
        || this.canAccess('UI_WORKLOAD:VERSION_EDIT')
        || this.canAccess('UI_WORKLOAD:VERSION_DELETE')
        || this.canAccess('UI_DEPLOY:DEPLOY')) {
        return [
          {
            text: '',
            width: '5px',
            component: {
              sfc: () => import('@/components/workloads/helpers/IsSnapshotEnabledAndImageIsNotQcow2.vue'),
              props: {
                workload: this.type !== 'docker-compose' ? this.workload : this.composeWorkload,
              },
            },
            sortable: false,
          },
          {
            text: this.$t('workloadDetail.headerName'),
            value: 'name',
            sortable: false,
            component: {
              sfc: () => import('@/components/workloads/helpers/WorkloadDetailReleasedIcon.vue'),
              props: {
                type: this.type,
              },
            },
          },
          {
            text: this.$t('workloadDetail.releaseName'),
            value: 'isDownloading',
            sortable: false,
            component: {
              sfc: () => import('@/components/workloads/helpers/WorkloadDetailReleaseName.vue'),
              props: {
                type: this.type,
              },
            },
          },
          {
            text: this.$t('defaultTable.action'),
            value: '',
            width: '10px',
            sortable: false,
            component: {
              sfc: () => import('@/components/workloads/helpers/WorkloadDetailTableActions.vue'),
              props: {
                iconsAction: ['CancelCreation', 'DisabledCancelCreation', 'Copy', 'DisabledCopy', 'Deploy', 'DisabledDeploy', 'Clone', 'DisabledClone', 'Export', 'DisabledExport', 'Delete', 'DisabledDelete'],
                fetchActionUpdate: () => this.goToWorkloadVersionSpecificUpdate,
                fetchActionClone: () => this.goToWorkloadVersionSpecificClone,
                workloadId: this.type !== 'docker-compose' ? this.workload._id : this.composeWorkload._id,
                workload: this.type !== 'docker-compose' ? this.workload : this.composeWorkload,
              },
            },
          },
        ];
      }
      return [
        {
          text: '',
          width: '5px',
          component: {
            sfc: () => import('@/components/workloads/helpers/IsSnapshotEnabledAndImageIsNotQcow2.vue'),
            props: {
              workload: this.type !== 'docker-compose' ? this.workload : this.composeWorkload,
            },
          },
          sortable: false,
        },
        {
          text: this.$t('workloadDetail.headerName'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('workloadDetail.releaseName'),
          value: 'isDownloading',
          sortable: false,
          component: {
            sfc: () => import('@/components/workloads/helpers/WorkloadDetailReleaseName.vue'),
            props: {
              type: this.type,
            },
          },
        },
        {
          text: '',
          value: 'icon',
          width: '50px',
          component: {
            sfc: () => import('@/components/workloads/helpers/WorkloadDetailReleasedIcon.vue'),
            props: {
              type: this.type,
            },
          },
          sortable: false,
        },
      ];
    },
    workload() {
      return this.$store.getters['workloads/getWorkload'];
    },
    isAddEditWorkloadVersionPage() {
      return this.$store.getters['workloads/isAddEditWorkloadVersionPage'];
    },
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadDetail.required'),
        name_contains_only_spaces: (value) => !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES
          .test(value) || this.$t('workloadDetail.wlNameContainOnlySpaces'),
        name_contains_control_character: (value) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS
          .test(value) || this.$t('workloadDetail.wlNameContainControlCharacter'),
      };
    },
    composeWorkload() {
      return this.$store.getters['workloads/getComposeWorkload'];
    },
  },

  async mounted() {
    try {
      const route = window.location.pathname.split('/');
      this.isUpdate = route[route.length - 2] !== 'new';
      this.type = route[route.length - 1];
      if (!this.isAddEditWorkloadVersionPage || this.isUpdate) {
        await this.$store.dispatch(this.type !== 'docker-compose'
          ? 'workloads/get_workload_by_id' : 'workloads/get_compose_workload_by_id', route[route.length - 2]);
      }
      this.workloadName = this.type !== 'docker-compose' ? this.workload.name : this.composeWorkload.name;
      this.workloadDescription = this.type !== 'docker-compose' ? this.workload.description : this.composeWorkload.description;
      this.hasFormChanged = this.isUpdate ? (this.type !== 'docker-compose' ? this.workload.name : this.composeWorkload.name) !== this.workloadName
        || (this.type !== 'docker-compose' ? this.workload.description : this.composeWorkload.description) !== this.workloadDescription : true;
      this.$refs.workloadDetailForm.$el.addEventListener('input', () => {
        this.hasFormChanged = this.isUpdate ? (this.type !== 'docker-compose' ? this.workload.name : this.composeWorkload.name) !== this.workloadName
        || (this.type !== 'docker-compose' ? this.workload.description : this.composeWorkload.description) !== this.workloadDescription : true;
      });

      // clear old name/description of the workload
      this.$store.dispatch('workloads/setOldNameAndDesc', {
          name: '',
          desc: '',
        });
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    rowClicked(version) {
      if (version.isDownloading) {
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'errorMessages.nerve_workload_155',
          color: 'red',
          showClose: true,
        });
        return;
      }
      this.$store.dispatch('workloads/setNameAndDesc', {
        name: this.type !== 'docker-compose' ? this.workload.name : this.composeWorkload.name,
        desc: this.type !== 'docker-compose' ? this.workload.description : this.composeWorkload.description,
      });
      if (this.type === 'docker-compose') {
        // Set old name and description for docker-compose workload
        // This will be used on version creation or update to update
        // workload if there were any unsaved changes
        this.$store.dispatch('workloads/setOldNameAndDesc', {
          name: this.workloadName,
          desc: this.workloadDescription,
        });
      }
      this.$router.push({
        name: 'Add edit workload version',
        params: {
          workloadId: (this.type !== 'docker-compose' ? this.workload._id : this.composeWorkload._id),
          versionId: version._id,
          type: this.type,
        },
      });
    },
    cancel() {
      this.$router.push({ name: 'Workloads', query: this.$store.getters['labels/getQuery'] });
    },
    async submitForm() {
      try {
        if (!this.$refs.workloadDetailForm.validate()) {
          return;
        }
        if (!this.isUpdate) {
          if (this.type !== 'docker-compose') {
            const fd = new FormData();
            this.workload.type = this.type;
            fd.append('data', JSON.stringify(this.workload));
            fd.append('file1', JSON.stringify({}));
            fd.append('file2', JSON.stringify({}));
            await this.$store.dispatch('workloads/create_workload', fd);
          } else {
            // When creating docker-compose workload information about versions are not sent
            // so form is not needed
            this.composeWorkload.type = this.type;
            const {
              type, name, description, disabled,
            } = this.composeWorkload;
            // Only there 4 properties are sent because of the api validation
            await this.$store.dispatch('workloads/create_compose_workload', {
              type, name, description, disabled,
            });
          }
          this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'workloadDetail.successfullyCreate',
            color: 'green',
            showClose: true,
          });
          this.$router.push({ name: 'Workloads', query: this.$store.getters['labels/getQuery'] });
          return;
        }
        // No need to send versions - not used at all on BE
        // It is an endpoint to update only workload data, not versions
        if (this.type !== 'docker-compose') {
          this.workload.versions = [];
          await this.$store.dispatch('workload-detail/update_workload', this.workload);
        } else {
          // When updating docker-compose workload payload which is sent to the BE contains
          // only changed properties (name, description or both)
          const data = {
            ...(this.workloadName !== this.composeWorkload.name
            && { name: this.composeWorkload.name }),
            ...(this.workloadDescription !== this.composeWorkload.description
            && { description: this.composeWorkload.description }),
          };
          await this.$store.dispatch('workloads/update_compose_workload', { id: this.composeWorkload._id, data });
        }
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
        this.$router.push({ name: 'Workloads', query: this.$store.getters['labels/getQuery'] });
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
        Logger.error(e);
      }
    },
    goToWorkloadVersionSpecificUpdate(version) {
      if (this.type === 'docker-compose') {
        this.$store.dispatch('workloads/setNameAndDesc', { name: this.composeWorkload.name, desc: this.composeWorkload.description });
        this.$store.dispatch('workloads/setOldNameAndDesc', { name: this.workloadName, desc: this.workloadDescription });
        this.$router.push({ name: 'Add edit workload version', params: { workloadId: this.composeWorkload._id, versionId: version._id, type: this.type } });
        return;
      }
      this.$store.dispatch('workloads/setNameAndDesc', { name: this.workload.name, desc: this.workload.description });
      this.$router.push({ name: 'Add edit workload version', params: { workloadId: this.workload._id, versionId: version._id, type: this.type } });
    },
    goToWorkloadVersionSpecificClone(version) {
      try {
        if (this.type === 'docker-compose') {
          this.$store.dispatch('workloads/setNameAndDesc', { name: this.composeWorkload.name, desc: this.composeWorkload.description });
          this.$store.dispatch('workloads/setOldNameAndDesc', { name: this.workloadName, desc: this.workloadDescription });
          this.$store.dispatch('workloads/get_compose_version_by_id', { id: version._id, isUpdate: false, isClone: true });
          this.$router.push({ name: 'Add edit workload version', params: { workloadId: this.composeWorkload._id, versionId: 'clone', type: this.type } });
          return;
        }
        this.$store.dispatch('workloads/setNameAndDesc', { name: this.workload.name, desc: this.workload.description });
        this.$store.dispatch('workloads/get_version_by_id', { id: version._id, isUpdate: false });
        this.$router.push({ name: 'Add edit workload version', params: { workloadId: this.workload._id, versionId: 'clone', type: this.type } });
      } catch (e) {
        Logger.error(e);
      }
    },
    async goToWorkloadVersionSpecificAddWorkload() {
      this.$store.dispatch('workloads/setNameAndDesc', { name: this.workload.name, desc: this.workload.description });
      if (this.type !== 'docker-compose' && this.workload._id) {
        this.$router.push({ name: 'Add edit workload version', params: { workloadId: this.workload._id, versionId: 'new', type: this.type } });
        return;
      }
      if (this.type === 'docker-compose') {
        this.$store.dispatch('workloads/setNameAndDesc', { name: this.composeWorkload.name, desc: this.composeWorkload.description });
        if (this.isUpdate) {
          this.$store.dispatch('workloads/setOldNameAndDesc', { name: this.workloadName, desc: this.workloadDescription });
        }
        if (this.composeWorkload._id) {
          this.$router.push({ name: 'Add edit workload version', params: { workloadId: this.composeWorkload._id, versionId: 'new', type: this.type } });
          return;
        }
      }
      this.$router.push({ name: 'Add edit workload version', params: { workloadId: 'new', versionId: 'new', type: this.type } });
    },
    isEdit() {
      return !(window.location.pathname.split('/')[2] === 'new');
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
  },
};
</script>

<style lang="scss">
  .workload-icon {
    max-width: 25px;
    max-height: 25px;
  }
  .workload-icon .workload-icon-image {
    max-width: 15px;
    max-height: 15px;
  }
  #iiotAddEditWorkloadVersionTable {
    .v-data-table__wrapper {
      max-height: 50vh;
    }
  }
  .max-width-page {
    width: 99%;
  }
  #iiotAddEditWorkloadGoBackBtn:hover {
    cursor: pointer;
  }
  #iiotAddEditWorkloadNameInput:required::placeholder {
    color: var(--v-error-base) !important;
    opacity: 1;
  }
</style>
